<template>
    <div class="popup">
        <div class="box">
            <p class="popTitle">분석 이력</p>
            <div class="cont" @scroll="handleScroll">
                <ul class="recordList" v-if="isGetingItems">
                    <li v-for="n in 10" :key="n">
                        <ContentLoader :speed="1" width="100" :height="8">
                            <rect x="1" y="1" rx="3" ry="3" :width="80" :height="4" />
                        </ContentLoader>
                    </li>
                </ul>
                <div v-else-if="!isGetingItems && historyList && historyList.length == 0">
                    분석 이력이 없습니다.
                </div>
                <ul class="recordList" v-else>
                    <li v-for="(item, i) in historyList" :key="i">
                        <label>
                            <input type="checkbox" v-model="selHistoryList" :value="item.no"
                                :disabled="isCheckboxDisabled(i)">
                            {{ item.pictureDtStr }} <span class="smText">(등록일: {{ item.crtDtStr }})</span>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="btnW popBtn">
                <button class="btn default" @click="$emit('closePopup')" v-ripple>{{ CONST_CANCEL }}</button>
                <button class="btn solid" @click="selectHistory()" v-ripple>{{ CONST_OK }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import API_HISOTRY_LIST from '../API/reading/historyList';
import { ContentLoader } from 'vue-content-loader';

export default {
    components: {
        ContentLoader,
    },
    props: {
        xrayType: {
            default: '',
        },
        xrayNo: {
            default: '',
        },
        historyNoList: {
            default: () => [],
        },
    },
    data() {
        return {
            historyList: [],
            pageNo: 1,
            pageSize: 20,
            selHistoryList: [],
            isGetingItems: false,
            loading: false,
            reachedEnd: false,
        };
    },
    mounted() {
        this.getHistoryList();
    },
    methods: {
        async loadMoreHistorys() {
            if (this.loading || this.reachedEnd) return;
            this.loading = true;

            const nextPage = this.pageNo + 1;
            let res = await API_HISOTRY_LIST.request(nextPage, this.pageSize, this.xrayType, this.xrayNo);

            if (res.isSuccess) {
                this.historyList = [...this.historyList, ...res.list];
                this.pageNo = nextPage;
                this.updateSelectedHistory();
                this.loading = false;
                if (res.list.length === 0) {
                    this.reachedEnd = true;
                }
            } else {
                this.loading = false;
                this.showPopup(res.errorMsg, res.status);
            }
        },
        handleScroll(event) {
            const scrollHeight = event.target.scrollHeight;
            const scrollTop = event.target.scrollTop;
            const clientHeight = event.target.clientHeight;
            const bottomOffset = 100;

            if (scrollHeight - scrollTop - clientHeight <= bottomOffset) {
                this.loadMoreHistorys();
            }
        },
        async getHistoryList() {
            if (this.isGetingItems) return;
            this.isGetingItems = true;
            let res = await API_HISOTRY_LIST.request(this.pageNo, this.pageSize, this.xrayType, this.xrayNo);
            if (res.isSuccess) {
                this.historyList = res.list;
                this.updateSelectedHistory();
                if (res.list.length < this.pageSize) {
                    this.reachedEnd = true;
                }
                this.isGetingItems = false;
            } else {
                this.showPopup(res.errorMsg, res.status);
                this.isGetingItems = false;
            }
        },
        selectHistory() {
            if (this.xrayType !== 'spine') {
                this.showPopup('잘못된 X-ray 타입입니다.');
                return;
            }
            this.$emit('selectHistory', this.xrayType, this.selHistoryList);
        },
        updateSelectedHistory() {
            this.historyNoList.forEach((selectedNo) => {
                this.historyList.forEach((historyItem) => {
                    if (historyItem.no === selectedNo && !this.selHistoryList.includes(selectedNo)) {
                        this.selHistoryList.push(historyItem.no);
                    }
                });
            });
        },
        isCheckboxDisabled(index) {
            return this.selHistoryList.length >= 2 && !this.selHistoryList.includes(this.historyList[index].no);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";

.popup .box {
    width: 400px;

    .cont {
        min-height: 300px;
        max-height: 400px;
    }

    .popBtn {
        padding: 12px 0;
        border-top: 1px solid #dbdbdb;
    }

    .smText {
        font-size: 1.25rem;
        opacity: .8;
    }
}
</style>
