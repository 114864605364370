var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "popup"
  }, [_c('Loading', {
    attrs: {
      "size": "32",
      "isShow": _vm.loading,
      "fullscreen": true
    }
  }), !_vm.loading ? _c('div', {
    staticClass: "box"
  }, [_c('p', {
    staticClass: "popTitle"
  }, [_vm._v(_vm._s(_vm.xrayName) + " 변화 추이")]), _c('div', {
    staticClass: "cont"
  }, [_c('GraphCompo', {
    attrs: {
      "xrayType": _vm.xrayType,
      "xrayNo": _vm.xrayNo,
      "graphType": _vm.graphType
    },
    on: {
      "loading": function ($event) {
        _vm.loading = false;
      }
    }
  })], 1), _c('div', {
    staticClass: "btnW popBtn"
  }, [_c('button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple"
    }],
    staticClass: "btn default",
    on: {
      "click": function ($event) {
        return _vm.$emit('closePopup');
      }
    }
  }, [_vm._v(_vm._s(_vm.CONST_CLOSE))])])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }